import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Jusqu'au 30 septembre, à l'occasion de la semaine du développement durable, évitons le jetable lors de la vente à emporter ! 💚`}</p>
    <p>{`Rendez-vous dans le 10ème arrondissement de Paris chez les restos partenaires de `}<a parentName="p" {...{
        "href": "https://www.instagram.com/laconsignegreengo/"
      }}></a>{`La Consigne GreenGo ou ailleurs grâce aux restos partenaires de `}<a parentName="p" {...{
        "href": "https://www.instagram.com/explore/tags/oztaboite/"
      }}>{`#`}{`Oztaboite`}</a>{` qui acceptent votre propre contenant ! ♻️`}</p>
    <p>{`Tentez de gagner 10 repas pour 2 personnes dans l'un des restos partenaires de `}<a parentName="p" {...{
        "href": "https://www.instagram.com/laconsignegreengo/"
      }}></a>{`La Consigne GreenGo`}</p>
    <p>{`Comment faire pour participer au concours ?\\
✅ Poster en story Instagram la photo de votre plat emballé dans un emballage réutilisable\\
✅ Tagguez `}<a parentName="p" {...{
        "href": "https://www.instagram.com/laconsignegreengo/"
      }}>{`@laconsignegreengo`}</a>{` et `}<a parentName="p" {...{
        "href": "https://www.instagram.com/influence4impact/"
      }}>{`@influence4impact`}</a></p>
    <p>{`Rejoignez le mouvement du tout durable et prenez soin de la `}<a parentName="p" {...{
        "href": "https://www.instagram.com/explore/tags/plan%C3%A8te/"
      }}></a>{`planète 🌍`}</p>
    <p><strong parentName="p">{`Mentions légales du concours La Consigne GreenGo - « Gagnez 10 repas pour 2 personnes » :⁠`}</strong></p>
    <p>{`ARTICLE 1 : Le jeu concours organisé par La Consigne GreenGo intitulé « Gagnez 10 repas pour 2 personnes » est ouvert aux personnes qui résident France uniquement (hors corse et dom-tom). Le jeu concours débute le 21 septembre 2021 à 14h et se termine le 30 septembre 2021 à minuit. `}</p>
    <p>{`ARTICLE 2 : La participation à ce jeu nécessite d'acheter un repas chez l'un des restaurants partenaires de La Consigne GreenGo ou d'acheter un repas en apportant son propre contenant dans un restaurant parisien.`}</p>
    <p>{`ARTICLE 3 : Ce jeu-concours n’est ni organisé ni parrainé par Instagram.`}</p>
    <p>{`ARTICLE 4 : Le jeu prévoit 10 gagnants tirés au sort parmi toutes les personnes qui respectent les conditions de participation.`}</p>
    <p>{`ARTICLE 5 : Le gagnant sera informé par message privé au plus tard 7 jours après la fin du jeu concours. Ses coordonnées lui seront demandés pour pouvoir lui offrir son repas d'un montant maximal de 15€ chez l'un des restaurants partenaires La Consigne GreenGo `}</p>
    <p>{`ARTICLE 6 : La société La Consigne GreenGo ne pourra être tenue pour responsable si, pour des raisons indépendantes de sa volonté, l'opération, ses modalités et/ou les prix devaient être partiellement ou totalement reportés, modifiés ou annulés.`}</p>
    <p>{`ARTICLE 7 : Le simple fait de participer implique l'acceptation du règlement et des instructions. `}</p>
    <p>{`Ceci constitue le règlement complet du jeu « Gagner 10 repas pour 2 personnes » - SAS La Consigne GreenGo – 38 Rue Des Jeuneurs, 75002 Paris`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      